<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="医生团队 :">
        <span>{{ form.doctorInfo.teamName }}</span>
      </el-form-item>
      <el-form-item label="科室名称 :">
        <span>{{ form.doctorInfo.deptName }}</span>
      </el-form-item>
      <el-form-item label="医生名称 :">
        <span>{{ form.doctorInfo.doctorName }}</span>
      </el-form-item>
      <div
        v-for="(item, i) in form.setConsultationList"
        :key="item.openConsultationCode"
      >
        <template
          v-if="
            $route.query.type == 0 ||
            ($route.query.type == 1 && item.isShow && item.openStatus)
          "
        >
          <el-row type="flex" justify="middle">
            <el-col :span="6">
              <el-form-item :label="'问诊类型' + (i + 1) + ':'">
                <span>{{ item.consultationName }}</span>
              </el-form-item>
            </el-col>
            <template v-if="$route.query.type == 0">
              <el-col :span="6">
                <el-form-item label="是否开通:">
                  <span>{{ item.openStatus ? '开通' : '未开通' }}</span>
                </el-form-item>
              </el-col>
            </template>

            <el-col :span="6">
              <el-form-item
                label="价格:"
                :prop="`setConsultationList[${i}].price`"
                :rules="rules.price"
              >
                <el-input
                  v-model="item.price"
                  v-check-permission
                  :disabled="$route.query.type == 0"
                />
              </el-form-item>
            </el-col>
            <el-col v-if="item.consultationType === '120'" :span="6">
              <el-form-item
                class="payStatus"
                label="是否开通付费报到:"
                label-width="150px"
                :prop="`setConsultationList[${i}].payStatus`"
              >
                <el-switch
                  v-model="item.payStatus"
                  :disabled="$route.query.type == 0"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </div>
      <!-- 科研数据入口 -->
      <template>
        <el-form-item
          label="是否开启科研数据入口:"
          label-width="176px"
          :rules="rules.scientificResearch"
        >
          <el-switch
            v-model="form.scientificResearch.showStatus"
            :disabled="$route.query.type == 0"
          />
        </el-form-item>
        <el-form-item v-if="form.scientificResearch.showStatus" label-width="0">
          <el-input
            style="width: 50%"
            v-model.trim="form.scientificResearch.url"
            placeholder="请配置科研数据链接"
            :disabled="$route.query.type == 0"
          />
        </el-form-item>
      </template>
      <!-- 如果开通了专病服务类型显示其所在组的成员以及加入的组 -->
      <template>
        <el-form-item label="专病服务小组" />
        <h6>我的组</h6>
        <template v-if="isOwnerList.length > 0">
          <!-- <div
            v-for="(item,index) in isOwnerList "
            :key="item.id"
          > -->
          <div class="my-group">
            <div v-for="item1 in isOwnerList" :key="item1.doctorId">
              <div class="doctorInfo">
                <i
                  v-if="!item1.leader"
                  class="el-icon-error"
                  @click="deleteMyGroup(item1)"
                />
                <el-image :src="item1.doctorPhoto" class="avatar" fit="fill" />
                <div>{{ item1.doctorName }}</div>
              </div>
            </div>
            <div class="my-group-add">
              <el-button type="primary" @click="addMyGroup"> 添加 </el-button>
            </div>
          </div>
          <el-dialog title="" width="70%" :visible.sync="isVisible">
            <el-form ref="group" :inline="true" :model="group" small="size">
              <el-form-item label="医生姓名:">
                <el-input
                  v-model.trim="group.doctorName"
                  maxlength="20"
                  placeholder="请输入医生姓名"
                />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="queryData()">
                  查询
                </el-button>
                <el-button type="primary" @click="queryClear()">
                  重置
                </el-button>
              </el-form-item>
            </el-form>
            <el-table ref="table" v-loading="loading" :data="doctorList">
              <el-table-column type="index" label="序号" width="50" />
              <el-table-column
                prop="teamName"
                label="医生团队"
                align="center"
              />
              <el-table-column
                prop="doctorName"
                label="医生姓名"
                align="center"
              />
              <el-table-column prop="deptName" label="科室" align="center" />
              <el-table-column
                prop="professionalTitleName"
                label="职称"
                align="center"
              />
              <el-table-column prop="mobile" label="联系电话" align="center" />
              <el-table-column
                label="操作"
                show-overflow-tooltip
                width="200"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="addDoctor(scope.row)"
                  >
                    添加
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页功能模块 -->
            <div class="pagination">
              <el-pagination
                background
                :page-sizes="[10, 20, 50, 100, 200]"
                :current-page="pageInfo.page"
                :page-size="pageInfo.size"
                layout="->,total, sizes, prev, pager, next, jumper"
                :total="pageInfo.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </el-dialog>
        </template>
        <template v-else>
          <p>无</p>
        </template>
        <div class="more">
          <h6>我加入的组</h6>
          <div @click="expandCollapse">展开/收起</div>
        </div>

        <template v-if="noOwnerList.length > 0 && isMore">
          <div v-for="item in noOwnerList" :key="item.id">
            <p style="color: #52c41a">
              {{ item.name }}
            </p>
            <div class="my-group">
              <div v-for="item1 in item.member" :key="item1.doctorId">
                <div class="doctorInfo">
                  <el-image
                    :src="item1.doctorPhoto"
                    fit="fill"
                    class="avatar"
                  />
                  <div class="memberList-name">
                    {{ item1.doctorName }}
                  </div>
                  <div v-if="item1.leader">(组长)</div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- 管家权益卡 -->
        <div
          v-if="form.doctorEquityList && form.doctorEquityList.length > 0"
          class="equity"
        >
          <h6>我的权益卡</h6>
          <el-form-item label="C端权益卡价格展示和隐藏" label-width="178px">
            <el-switch
              v-model="form.priceShowStatus"
              :disabled="$route.query.type == 0"
            />
          </el-form-item>
          <div class="equity-list">
            <div
              v-for="(item, i) in form.doctorEquityList"
              :key="item.serviceCode"
              class="equity-item"
            >
              <div class="equity-content">
                <span>{{ item.serviceName }}</span>
                <span class="equity-content-price"
                  >{{
                    Number(item.price) ? (item.price / 100).toFixed(2) : 0
                  }}元</span
                >
              </div>
              <div class="equity-sort">
                <div class="equity-sort-label">序号</div>
                <el-form-item
                  :prop="`doctorEquityList[${i}].sort`"
                  :rules="rules.serialNumber"
                  label-width="0"
                >
                  <el-input
                    v-model="item.sort"
                    :disabled="$route.query.type == 0"
                  />
                </el-form-item>
              </div>
              <div>
                <span>诊后报道页隐藏</span>
                <el-switch
                  v-model="item.showStatus"
                  class="equity-switch"
                  :disabled="$route.query.type == 0"
                />
                &nbsp;&nbsp; <span>主页展示</span>
                <el-switch
                  v-model="item.showDoctorMainPageStatus"
                  class="equity-switch"
                  :disabled="$route.query.type == 0"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <el-form-item>
        <el-button v-if="isEdit" type="primary" @click="saveData()">
          保存
        </el-button>
        <el-button type="primary" @click="goBack"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { numRule, serialNumRule } from '@/utils/verificationRule.js';
export default {
  name: 'Details',
  data() {
    return {
      loading: false,
      isEdit: Number(this.$route.query.type) === 1,
      isMore: true, // 展示全部我加入的组
      isVisible: false,
      groupId: '', // 小组id
      doctorList: [],
      group: {
        doctorName: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      form: {
        doctorInfo: {
          deptName: '',
          teamName: '',
          doctorName: '',
        },
        setConsultationList: [],
        scientificResearch: {
          showStatus: false,
          url: '',
        },
      },
      rules: {
        price: [
          { required: true, validator: numRule, trigger: 'change', max: 9999 },
        ],
        serialNumber: [
          {
            required: true,
            validator: serialNumRule,
            trigger: 'change',
            max: 99,
          },
        ],
        scientificResearch: [
          {
            required: true,
            message: '请选择是否开启科研数据入口',
            trigger: 'change',
          },
        ],
      },
      isOwnerList: [], // 我的组小组成员列表
      noOwnerList: [], // 我加入的组成员列表
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getConsultationDetail();
    // this.queryFindListByDoctorId();
  },
  methods: {
    ...mapActions('doctorManage', ['consultationGet', 'consultationEdit']),
    // 问诊配置详情
    getConsultationDetail() {
      const param = {
        id: this.$route.query.id,
      };
      this.consultationGet(param).then((res) => {
        this.form = res.data;
        if (Number(this.$route.query.type) === 1) {
          const list = res.data.setConsultationList;
          this.form.setConsultationList = list.filter((item) => item?.isShow);
        }
      });
    },

    // 查询医生所在小组以及成员
    queryFindListByDoctorId() {
      this.$api
        .findListByDoctorId({ doctorId: this.$route.query.id })
        .then((res) => {
          const { data } = res;
          this.groupId = data?.[0]?.id;
          this.isOwnerList = data.filter((item) => item.isOwner)[0].member;
          this.noOwnerList = data.filter((item) => !item.isOwner);
        });
    },
    // 编辑科室时，保存
    saveData() {
      const {
        setConsultationList,
        priceShowStatus,
        doctorEquityList,
        scientificResearch,
      } = this.form;
      this.$refs['form'].validate((valid, object) => {
        console.log('编辑保存========>', valid, '-------->', object);
        if (valid) {
          const param = {
            doctorId: this.$route.query.id,
            setConsultationList,
            priceShowStatus,
            doctorEquityList,
            scientificResearch,
          };
          this.consultationEdit(param).then((res) => {
            if (res.code === 0) {
              this.$message.success('编辑成功');
              this.$router.go(-1);
            }
          });
        }
      });
    },
    // 添加小组成员弹窗
    addMyGroup() {
      this.isVisible = true;
      this.queryClear();
    },
    // 添加小组成员
    addDoctor(group) {
      const { id: doctorId, doctorName } = group;
      const { groupId } = this;
      const leaderId = this.isOwnerList.filter((item) => item.leader)?.[0]
        ?.doctorId;
      const params = {
        doctorId,
        doctorName,
        groupId,
        leaderId,
      };
      this.$api
        .addDoctor(params)
        .then(async (res) => {
          if (res.code === 0) {
            await this.queryFindListByDoctorId();
            this.$message({
              type: 'success',
              message: '添加成功!',
            });
            this.isVisible = false;
          }
        })
        .catch(() => {});
    },
    // 删除小组成员
    deleteMyGroup(group) {
      const { doctorId, doctorName } = group;
      const { groupId } = this;
      this.$confirm(`删除小组成员${doctorName}，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const param = {
            doctorId,
            doctorName,
            groupId,
          };
          this.$api.removeDoctor(param).then(async (res) => {
            if (res.code === 0) {
              await this.queryFindListByDoctorId();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            }
          });
        })
        .catch(() => {});
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.queryDoctorList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.queryDoctorList();
    },
    // 重置
    queryClear() {
      this.group = {
        doctorName: '',
      };
      this.pageInfo = {
        page: 1,
        size: 10,
        total: 0,
      };
      this.queryDoctorList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.queryDoctorList();
    },
    // 分页查询问诊类型列表
    queryDoctorList() {
      this.loading = true;
      const params = {
        ...JSON.parse(JSON.stringify(this.group)),
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.$api
        .queryFurtherConsultationDoctorList(params)
        .then((res) => {
          this.doctorList = res.data.data;
          this.pageInfo.total = Number(res.data.total);
          this.loading = false;
        })
        .catch(() => {});
    },
    goBack() {
      this.$router.go(-1);
    },
    expandCollapse() {
      this.isMore = !this.isMore;
    },
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}

.serviceDetail {
  font-size: 16px;
  color: red;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.pagination {
  overflow: hidden;
}

.my-group {
  display: flex;
  flex-wrap: wrap;
  .doctorInfo {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    .memberList-name {
      margin-bottom: 10px;
    }
    .el-icon-error {
      position: absolute;
      top: -10px;
      right: 0;
      color: #ff4949;
      font-size: 20px;
      z-index: 9;
    }
    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 5px;
      display: block;
      margin: 10px 10px 10px 0px;
    }
  }

  .my-group-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 120px;
  }
}
.more {
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: space-between;
}

.payStatus {
  margin-left: 15px;
}

.equity {
  .equity-list {
    padding-bottom: 20px;
  }

  .equity-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 18px;
    .equity-content {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 300px;
      height: 48px;
      padding: 0 15px;
      margin-right: 25px;
      color: #fff;
      border-radius: 3px;
      background: #1890ff;

      .equity-content-price {
        margin-left: 15px;
      }
    }

    .equity-sort {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 25px;

      .equity-sort-label {
        width: 48px;
      }

      .el-form-item {
        margin-bottom: 0 !important;
      }
    }

    .equity-switch {
      margin-left: 10px;
    }
  }
}
</style>
